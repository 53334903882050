/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import Bg from '../../images/contact/contact-info.jpg';
import Spinner from '../layout/Spinner';

const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const [submitForm, { loading, data }] = useMutation(CONTACT_MUTATION);

  const handleFormSubmit = () => {
    submitForm({
      variables: {
        clientMutationId: `${new Date().toUTCString()}`,
        name,
        email,
        phone,
        message,
      },
    });
  };

  const renderFormBody = () => (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleFormSubmit();
      }}
    >
      <div className="double-input">
        <div className="input-group">
          <label htmlFor="nameInput">
            Imię i nazwisko
            <input id="nameInput" onChange={(e) => setName(e.target.value)} type="text" required />
          </label>
        </div>
        <div className="input-group">
          <label htmlFor="phoneInput">
            Telefon
            <input id="phoneInput" onChange={(e) => setPhone(e.target.value)} type="text" required />
          </label>
        </div>
      </div>
      <label htmlFor="emailInput">
        Email
        <input id="emailInput" onChange={(e) => setEmail(e.target.value)} type="email" required />
      </label>
      <label htmlFor="messageInput">
        Wiadomość
        <textarea id="messageInput" value={message} onChange={(e) => setMessage(e.target.value)} required />
      </label>

      <button type="submit">Wyślij</button>
    </form>
  );

  return (
    <section className="contact">
      <div
        className="contact__info"
        style={{
          background: `url(${Bg})`,
        }}
      />
      <div className="contact__form">
        <div className="left-column">
          <h3>Kontakt</h3>
          {/* <h4>Adres</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Inventore, veniam?
          </p> */}
          <h4>Email</h4>
          <p>tangolodz@o2.pl</p>
          <h4>Telefon</h4>
          <p>
            Kuba
            <span> </span>
            606-622-777
          </p>
          <p>
            Małgosia
            <span> </span>
            604-171-976
          </p>
        </div>
        <div className="right-column">
          {loading && <Spinner />}
          {!loading && (data ? <h3>Dziękujemy za kontakt</h3> : renderFormBody())}
        </div>
      </div>
    </section>
  );
};

export default Contact;

const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $name: String!
    $email: String!
    $phone: String!
    $message: String!
  ) {
    createSubmission(
      input: { clientMutationId: $clientMutationId, name: $name, email: $email, phone: $phone, message: $message }
    ) {
      success
      data
    }
  }
`;
